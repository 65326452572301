import React, {PropsWithChildren} from 'react';

import {
    ApplicationContext,
    ApplicationProvider,
    useAppContext,
} from './ApplicationContext';
import {
    NotificationContext,
    NotificationProvider,
    useNotificationContext,
} from './NotificationContext';
import {DashboardProvider, useDashboardContext} from './DashboardContext';
import {JobContext, JobProvider, useJobContext} from './JobContext';
import {ProductProvider, useProductContext} from './ProductContext';
import {urlPartsJob} from 'shared/helpers';
import {DialogProvider, useDialogContext} from './DialogContext';
import {NoAuthContextManager} from './NoAuthContextManager';

/**
 * This component is used to maintain the Context Hierarchy.
 *
 * @param {ReactNode} children
 *
 * @return {ReactNode}
 */
export const ContextManager = ({children}: PropsWithChildren<object>) => {
    const [jobId, roomId] = urlPartsJob();

    return (
        <DialogProvider>
            <NotificationProvider>
                <ApplicationProvider>
                    <JobProvider jobId={jobId} roomId={roomId}>
                        {children}
                    </JobProvider>
                </ApplicationProvider>
            </NotificationProvider>
        </DialogProvider>
    );
};

export {
    ApplicationContext,
    ApplicationProvider,
    useAppContext,
    NotificationProvider,
    NotificationContext,
    useNotificationContext,
    DashboardProvider,
    useDashboardContext,
    JobProvider,
    JobContext,
    useJobContext,
    ProductProvider,
    useProductContext,
    useDialogContext,
    NoAuthContextManager,
};
