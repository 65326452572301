import React, {PropsWithChildren} from 'react';
import {urlPartsJob} from 'shared/helpers';
import {DialogProvider} from 'contexts/DialogContext';
import {NotificationProvider} from 'contexts/NotificationContext';
import {JobProvider} from 'contexts/JobContext';

export const NoAuthContextManager = ({children}: PropsWithChildren<object>) => {
    const [jobId, roomId] = urlPartsJob();

    return (
        <DialogProvider>
            <NotificationProvider>
                <JobProvider jobId={jobId} roomId={roomId}>
                    {children}
                </JobProvider>
            </NotificationProvider>
        </DialogProvider>
    );
};
