import React, {
    useEffect,
    createContext,
    useContext,
    useState,
    ReactElement,
} from 'react';
import {useAppContext} from 'contexts';
import {Job} from 'shared/types/PartialJob';
import {User} from 'components/customer/Auth/store/userSlice';

interface DashboardContextInterface {
    lastJob?: Job;
    setLastJob: (job: Job) => void;
    isQuotedJobsVisibleMobile: boolean;
    setIsQuotedJobsVisibleMobile: (v: boolean) => void;
    isPendingConfirmationJobsVisibleMobile: boolean;
    setIsPendingConfirmationJobsVisibleMobile: (v: boolean) => void;
    isLeadTimeVisibleMobile: boolean;
    setIsLeadTimeVisibleMobile: (v: boolean) => void;
    isProductionJobsVisibleMobile: boolean;
    setIsProductionJobsVisibleMobile: (v: boolean) => void;
    isPendingJobsVisibleMobile: boolean;
    setIsPendingJobsVisibleMobile: (v: boolean) => void;
    isPendingPaymentJobsVisibleMobile: boolean;
    setIsPendingPaymentJobsVisibleMobile: (v: boolean) => void;
    isCompletedJobsVisibleMobile: boolean;
    setIsCompletedJobsVisibleMobile: (v: boolean) => void;
    isUpdateCenterJobsVisibleMobile: boolean;
    setIsUpdateCenterJobsVisibleMobile: (v: boolean) => void;
    jobInfo?: JobMenuInfo;
    setJobInfo: (v: JobMenuInfo) => void;
    hasLeadTime: boolean;
    setHasLeadTime: (v: boolean) => void;
    userProfile: User;
}

const DashboardContext = createContext({} as DashboardContextInterface);

export const useDashboardContext = () => {
    return useContext(DashboardContext);
};

interface Props {
    children?: ReactElement;
}

interface JobMenuInfo {
    'production-jobs'?: number;
    'quoted-jobs'?: number;
    'pending-confirmation-jobs'?: number;
    'pending-approval-jobs'?: number;
    'completed-jobs'?: number;
    'pending-payment-jobs'?: number;
}

export const DashboardProvider = ({children}: Props) => {
    const {setIsLeftDashboardButtonVisible, userProfile} = useAppContext();

    const [lastJob, setLastJob] = useState<Job | undefined>();
    const [isQuotedJobsVisibleMobile, setIsQuotedJobsVisibleMobile] =
        useState<boolean>(false);
    const [
        isPendingConfirmationJobsVisibleMobile,
        setIsPendingConfirmationJobsVisibleMobile,
    ] = useState<boolean>(false);
    const [isLeadTimeVisibleMobile, setIsLeadTimeVisibleMobile] =
        useState<boolean>(false);
    const [isProductionJobsVisibleMobile, setIsProductionJobsVisibleMobile] =
        useState<boolean>(false);
    const [isPendingJobsVisibleMobile, setIsPendingJobsVisibleMobile] =
        useState<boolean>(false);
    const [
        isPendingPaymentJobsVisibleMobile,
        setIsPendingPaymentJobsVisibleMobile,
    ] = useState<boolean>(false);
    const [isCompletedJobsVisibleMobile, setIsCompletedJobsVisibleMobile] =
        useState<boolean>(false);
    const [
        isUpdateCenterJobsVisibleMobile,
        setIsUpdateCenterJobsVisibleMobile,
    ] = useState<boolean>(true);

    const [jobInfo, setJobInfo] = useState<JobMenuInfo>({
        'pending-confirmation-jobs': 1,
        'production-jobs': 1,
        'quoted-jobs': 1,
        'pending-approval-jobs': 1,
        'completed-jobs': 1,
        'pending-payment-jobs': 1,
    });
    const [hasLeadTime, setHasLeadTime] = useState<boolean>(false);

    useEffect(() => {
        setIsLeftDashboardButtonVisible(false);
    }, []);

    return (
        <DashboardContext.Provider
            value={{
                lastJob,
                setLastJob,
                isQuotedJobsVisibleMobile,
                setIsQuotedJobsVisibleMobile,
                isPendingConfirmationJobsVisibleMobile,
                setIsPendingConfirmationJobsVisibleMobile,
                isLeadTimeVisibleMobile,
                setIsLeadTimeVisibleMobile,
                isProductionJobsVisibleMobile,
                setIsProductionJobsVisibleMobile,
                isPendingJobsVisibleMobile,
                setIsPendingJobsVisibleMobile,
                isPendingPaymentJobsVisibleMobile,
                setIsPendingPaymentJobsVisibleMobile,
                isCompletedJobsVisibleMobile,
                setIsCompletedJobsVisibleMobile,
                isUpdateCenterJobsVisibleMobile,
                setIsUpdateCenterJobsVisibleMobile,
                jobInfo,
                setJobInfo,
                hasLeadTime,
                setHasLeadTime,
                userProfile,
            }}>
            {children}
        </DashboardContext.Provider>
    );
};
